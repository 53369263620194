import {useNavigate, Link} from 'react-router-dom';
import HelperService from '../../services/helper.service';

const Header = () => {

    const navigate = useNavigate();  

    const closeApp = () => {        
        HelperService.hideShop()
        return navigate(window.location.pathname)
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const path = queryParameters.get('path');

    let singleproduct;
    if (path && path.includes('/')){
        let finalpath = path.split('/');
        singleproduct = finalpath[2];
    }
 
    let back = '';
    if (path && ((path.includes('/') && !singleproduct) || path.includes('checkout'))) {
        back = <Link to={'..'} className='back' onClick={(e) => { e.preventDefault(); navigate(-1);}}>zurück</Link>
    }
    return (
        <div className='header'>
            <div className='container'>
                <span className='close' onClick={() => closeApp()}></span>
                <span className='logo'></span>
                {back}
            </div>
        </div>
    );                   
}

export default Header;
