import { Component } from 'react';
import ApiService from "../services/api.service";

export default class ErrorBoundary extends Component {

    state = {
        hasError: false,
        errormessage: ''
    };


    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            errormessage: error
        };
    }

    componentDidMount() {
        if (global.config === 'NOT_ALLOWED_DOMAIN' || global.config === 'SERVER_NO_ANSWER') {
            this.setState({ hasError: true, errormessage: global.config });
        }
    }

    componentDidCatch(error) {
        if (!this.state.hasError) {
            if (error.name === 'AxiosError') {
                this.setState({ errormessage: error.code });
            }
            else {
                this.setState({ errormessage: error });
            }
        }
    }

    render() {
        if (this.state.hasError) {
            let contentsection;
            let reacterror = false;
            switch (this.state.errormessage) {
                case 'NOT_ALLOWED_DOMAIN':
                    reacterror = false
                    contentsection = <p><strong>Dieser Seite fehlt die nötige Berechtigung!</strong><br /><br />Um den gewünschten Shop anzuzeigen, muss ein Administrator kontaktiert werden.</p>
                    break;
                case 'ERR_NETWORK':
                    reacterror = false
                    contentsection = <p><strong>Die Verbindung zu unserem Server wurde leider unterbrochen.</strong><br /><br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                    break;
                case 'NO_IDENTIFIER':
                    reacterror = false
                    contentsection = <p><strong>Diese Seite ist nicht ohne gültige Kennung aufrufbar.</strong><br /><br />Der Vorgang muss erneut ausgeführt werden.</p>
                    break;
                case 'NO_APPID':
                    reacterror = false
                    contentsection = <p>Diese Seite ist nicht ohne gültige AppId aufrufbar.<br /><br />Der Vorgang muss aus der App heraus erneut ausgeführt werden.</p>
                    break;
                case 'NO_CONTINGENT':
                    reacterror = false
                    contentsection = <p>Ein ausgewähltes Produkt ist leider nicht mehr verfügbar.<br /><br />Der Bestellprozess muss erneut gestartet werden.</p>
                    break;
                case 'WRONG_PREPAID':
                    reacterror = false
                    contentsection = <p>Das angegebene Prepaid-Konto ist ungültig.<br /><br />Der Bestellprozess muss erneut gestartet werden.</p>
                    break;
                case 'PREPAID_LIMIT':
                    reacterror = false
                    contentsection = <p>Das vorhandene Prepaid-Guthaben reicht leider nicht.<br /><br />Der Bestellprozess muss erneut gestartet werden.</p>
                    break;
                case 'SERVER_NO_ANSWER':
                    reacterror = false
                    contentsection = <p>Der Server hat leider keine Antwort geliefert.</p>
                    break;
                case 'NO_CONNECTION':
                    reacterror = false
                    contentsection = <p>Derzeit ist leider keine Verbindung zu unserem Server möglich.<br /><br />In wenigen Minuten sollte alles wieder funktionieren.</p>
                    break;
                case 'PARAMS_MISSING':
                    reacterror = false
                    contentsection = <p>Es fehlen notwendige Parameter.<br /><br />Die Seite muss erneut geladen werden.</p>
                    break;
                default:
                    reacterror = false
                    contentsection = <p>Bitte versuche es erneut.</p>
                    break;
            }


            if (reacterror) {
                const API_URL = global.config.express.url + '/senderrormail';
                const API_DATA = {
                    'url': window.location.href,
                    'error': this.state.errormessage.message,
                    'errorstack': this.state.errormessage.stack
                }
                ApiService.getData(API_URL, API_DATA);
                reacterror = false
            }


            return (
                <>
                    <div className='header'>
                        <div className='container'>
                            <span className='logo'></span>
                        </div>
                    </div>
                    <div className='container t-center'>
                        <h1>Ein Fehler ist aufgetreten</h1>
                        {contentsection}
                    </div>
                </>
            );
        }
        return this.props.children;
    }

}
