import { useSelector } from 'react-redux';
import HelperService from '../../services/helper.service';

const CheckoutOverview = ({productsArray, email, prepaidNumber, emailError, setEmailError, payment, setError}) => {
    
    const products = useSelector(state => state.global.qtys)
    const payments = useSelector(state => state.global.payments)
    const appid = useSelector(state => state.global.appid)
    const appticketnumber = useSelector(state => state.global.appticketnumber)
    
    const normalCheckout = (e) => {
        // W3C-Standard
        // let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;

        // eslint-disable-next-line
        let regex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        if (email === ''){
            setEmailError('Deine E-Mail-Adresse fehlt.')
        }
        else if (!email.match(regex) ) {
            setEmailError('Deine E-Mail-Adresse stimmt nicht.')
        }
        else {         
            let buttons = document.getElementsByClassName('payment')
            for ( var i = 0, l = buttons.length; i < l; i++ ){
                buttons[i].setAttribute('disabled', true);
            } 
            e.currentTarget.setAttribute('disabled', true);
            e.currentTarget.classList.add('loading');
            if (!payment){
                let activePaymentElement = document.querySelectorAll('.paymentoption.activ')[0]
                payment = parseInt(activePaymentElement.getAttribute('dataid'))
            }
            HelperService.checkout(email,prepaidNumber,products,payments,payment,setError,appid,appticketnumber)
        }
    }

    let totalprice = 0;
    let totalvat = 0;
    let taxes = []

    productsArray.forEach(p => {
        let id = productsArray.findIndex(obj => obj.id === p.id)
        if (productsArray[id].qty !== 0){
            totalprice = totalprice + productsArray[id].price * productsArray[id].qty
            totalvat = totalvat + productsArray[id].vat * productsArray[id].qty
            if (typeof(taxes[productsArray[id].tax]) === 'undefined') {
                taxes[productsArray[id].tax] = 0
            }
            taxes[productsArray[id].tax] = taxes[productsArray[id].tax] + productsArray[id].vat * productsArray[id].qty           
        }
    });

    const taxlines = Object.keys(taxes).map(key => 
        <div key={key}>
        <span className='left'>zzgl. {key}% MwSt.</span><span className='right t-right'>{HelperService.formatPrice(taxes[key])}</span>
        </div>
    )

    let overview = 
        <div className='container'>
            <div className='overviewbox'>
                <h2>Zusammenfassung</h2>
                <span className='left'>Zwischensumme</span><span className='right t-right'>{HelperService.formatPrice(totalprice)}</span>
                <span className='left'>Versandkosten</span><span className='right t-right'>{HelperService.formatPrice(0)}</span>
                <span className='left sum'>Gesamtsumme</span><span className='right t-right sum'>{HelperService.formatPrice(totalprice)}</span>
                <span className='left'>Gesamtnettosumme</span><span className='right t-right'>{HelperService.formatPrice(totalprice - totalvat)}</span>
                {taxlines}
                <div className='submitbox'>
                    {emailError ? <span className='error'>{emailError}</span> : ''}
                    <button className='submit full' onClick={(e) => normalCheckout(e)}>Zahlungspflichtig bestellen</button>
                </div>
            </div> 
        </div>   

    return overview

}

export default CheckoutOverview;
