import ApiService from './api.service';

class HelperService {

    formatPrice(data) {
        let locale = 'de';
        let options = {style: 'currency', currency: 'eur', minimumFractionDigits: 2, maximumFractionDigits: 2};
        let formatter = new Intl.NumberFormat(locale, options);
        return (
            formatter.format(data)
        )
    }
        
    hideShop(){
        let shopelement = document.getElementById('karlsticketshop');
        shopelement.classList.remove('opened');      
        document.getElementById('karlsticketshopoverlay').remove();         
    }

    checkout(email,prepaidNumber,products,payments,payment,setError,appid,appticketnumber){
        let cartProducts = []
        products.forEach(p => {
            if(p.qty > 0){
                cartProducts.push(p)
            }
        })
        let paymentObject;
        payments.forEach((p) => {    
            p['payment-call-tupel'].forEach((item, i) => {
                if (item['id'] === payment){
                    paymentObject = {
                        "payment-name": item['payment-name'],
                        "payment-provider": item['payment-provider'],
                        "payment-method-category": item['payment-method-category'],
                        "payment-method-value": item['payment-method-value'],
                        "payment-kind": item['payment-kind']
                    }
                }
            });
        });

        const API_URL = global.config.express.url + '/order/set';
        const API_DATA = {
            'shop-url': global.config.shopurl,
            'products': cartProducts,
            'payment': paymentObject,
            'prepaidnumber': prepaidNumber ? prepaidNumber : null,
            'email': email,
            'framepage': window.location.origin + window.location.pathname + '?mode=react',
            'app': global.config.inapp,
            'appid': appid,
            'appticketnumber':appticketnumber
        }

        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof(response.apierror) !== 'undefined'){
                setError(response.apierror);
            }
            else {
                if (response.url){
                    window.location.replace(response.url);    
                }
                if (response.script){
                    const script = document.createElement('script');
                    script.innerHTML = response.script;
                    document.getElementsByTagName('head')[0].appendChild(script);                              
                    const overlay = document.createElement('div');
                    overlay.classList.add('karls_checkout_overlay');
                    overlay.innerHTML = '<div class="infobox"><h2>Amazon Pay</h2><p>Mit Amazon Pay können Sie die in Ihrem Amazon-Account hinterlegten Zahlungs- und Versandinformationen nutzen, um schnell und sicher einzukaufen. Zudem genießen Sie den Käuferschutz, den Amazon mit der A-Z Garantie bietet.</p></div>';
                    document.getElementsByTagName('body')[0].appendChild(overlay);
                    document.getElementsByClassName('infobox')[0].appendChild(document.getElementById('AmazonPayButton'));
                    document.getElementById('AmazonPayButton').style.display = 'block';
                    var isMobileSafari = !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i);
                    setTimeout(function(){
                        var infobox = document.getElementsByClassName('infobox')[0];
                        var top = window.innerHeight / 2 - (infobox.offsetHeight / 2 + 50);
                        if (isMobileSafari){
                            infobox.style.width = '100%';
                            infobox.style.left = '0';
                            infobox.style.top = top + 'px';
                        }
                        else {
                            var left = window.innerWidth / 2 - infobox.offsetWidth / 2;                                   
                            infobox.style.left = left + 'px';
                            infobox.style.top = top + 'px';              
                        }
                        infobox.style.opacity = 1;
                    },1000);  
                }                 
            }
        }).catch((error) => {            
                setError('NO_CONNECTION')             
        });

    }


}

const exportData = new HelperService();
export default exportData;
