import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ApiService from '../services/api.service';
import { useDispatch, useSelector } from 'react-redux';
import * as GlobalStore from '../store/global/actions';

import appscreens from '../images/appscreens.png';
import iosstore from '../images/ios-store.png';
import googlestore from '../images/google-play-store.png';

const StartPage = () => {

    useLocation();

    const dispatch = useDispatch();

    const queryParameters = new URLSearchParams(window.location.search);
    const appid = queryParameters.get('karlsplus-app-id');
    const appticketnumber = queryParameters.get('ticket-number');
    const [response, setResponse] = useState();
    const [error, setError] = useState(null);


    const prePageState = useSelector(state => state.global.prepagehidden)
    const [prePageHidden, setPrePageHidden] = useState(prePageState);


    let prePage;

    const setPrePageState = (state) => {
        setPrePageHidden(state);
        dispatch(GlobalStore.setPrePageHidden(state));
    }


    if (appid) {
        dispatch(GlobalStore.setAppId(appid));
        if (prePageState === false) {
            setPrePageState(true);
        }
    }
    else {
        prePage =
            <div className='col-xs-12 prepage'>
                <h2>Lade dir jetzt die Karls-App herunter und spare bares Geld!</h2>
                <img className='full' src={appscreens} alt="App" />
                <Link to='https://apps.apple.com/de/app/karls/id1498904466'><img src={iosstore} alt="iOS App Store" /></Link>
                <Link to='https://play.google.com/store/apps/details?id=de.karls.karls'><img src={googlestore} alt="Google Play Store" /></Link>
                <p><br /><br />Alternativ kannst du natürlich auch etwas teurer direkt im Ticketshop bestellen.</p>
                <button type='button' onClick={() => setPrePageState(true)}>zum Ticketshop</button>
            </div>
    }

    if (appticketnumber) {
        dispatch(GlobalStore.setAppTicketNumber(appticketnumber));
    }
    else {
        dispatch(GlobalStore.setAppTicketNumber(null));
    }

    if (error) throw error;

    let categoryBoxes;
    let textAfter;

    if (response && response.categories) {
        let navbuttons = [];
        response.categories.forEach(e => {
            let imgpath = global.config.apiurl + '/' + e.image['relative-path'];
            navbuttons.push(
                <Link className='navbox' key={e.id} to={'?path=' + e.identifier}><img src={imgpath} alt={e.name} /></Link>
            )
        });
        textAfter = <div className='col-xs-12 text bottom'>Tickets werden für kostenpflichtige Attraktionen benötigt. Tageskarten sind nicht datumsgebunden und können jederzeit genutzt werden.<br /><br />Für das Karls Erlebnis-Dorf Warnsdorf bieten wir keine Tageskarten an: Tickets für die Traktorbahn können direkt vor Ort erworben werden.</div>
        categoryBoxes = <div className='col-xs-12'>{navbuttons}</div>
    }

    useEffect(() => {
        dispatch(GlobalStore.resetProductQtys());
        dispatch(GlobalStore.resetPayments());
        if (!response) {
            if (global.config.shopurl) {
                const API_URL = global.config.express.url + '/categories';
                const API_DATA = {
                    'shop-url': global.config.shopurl,
                    'app': global.config.inapp
                }
                ApiService.getData(API_URL, API_DATA).then((response) => {
                    if (typeof (response.apierror) !== 'undefined') {
                        setError(response.apierror);
                    }
                    else {
                        setResponse(response);
                    }
                }).catch((error) => { setError('NO_CONNECTION') });
            }
            else {
                setError('PARAMS_MISSING');
            }
        }
    }, [response, dispatch]);

    return (
        <>
            <div className='container t-center'>
                <div className='row'>
                    {!prePageHidden && prePage}
                    {prePageHidden ? categoryBoxes : ''}
                    {prePageHidden ? textAfter : ''}
                </div>
            </div>
        </>
    );
}

export default StartPage;
