import { useState } from "react";
import Cropper from "react-easy-crop";

const ImageCropper = ({ image, onCropDone, onCropCancel }) => 
{
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  return (
    <div className="cropper">
        <h1>Bild anpassen</h1>
        <div className="cropperholder">            
            <Cropper
                image={image}
                aspect={1}
                crop={crop}
                cropSize={{ width: 300, height: 300 }} 	
                zoom={zoom}
                zoomSpeed={0.1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}          
            />
            <div className="action-btns">               
                <button onClick={() => {onCropDone(croppedArea)}}>Speichern</button>
                <button className="link" onClick={onCropCancel}>Abbrechen</button>
            </div>
        </div>
    </div>
  );
}

export default ImageCropper;
