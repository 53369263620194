import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const OrderFailedPage = () => {  
    const error = useSelector(state => state.global.apierror);

    return (        
        <div className="container t-center">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                    <h1>Bestellung fehlgeschlagen</h1>                    
                    {error 
                    ? 
                    <p><strong>Wir haben folgende Ursache festgestellt:</strong><br />{error}</p> 
                    :   
                    <>
                    <p><strong>Mögliche Ursachen:</strong><br />&middot; Der Artikel ist bereits ausverkauft &middot;<br />&middot; Es kam zu einem Systemfehler &middot;</p>                     
                    </>
                    }
                    <p><br />Bitte versuche es noch einmal.</p>
                    <p><Link className='button' to="/">Zu den Tickets</Link></p>
                </div>
            </div>
        </div>
    );
}

export default OrderFailedPage;