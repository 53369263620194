import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FastCheckoutButtons from './checkoutpage/FastCheckoutButtons'
import OtherPayments from './checkoutpage/OtherPayments';
import CheckoutProducts from './checkoutpage/CheckoutProducts';
import CheckoutOverview from './checkoutpage/CheckoutOverview';

const CheckoutPage = () => {    

    const qtys = useSelector(state => state.global.qtys)

    const [productsArray, setProductsArray] = useState(qtys)

    const [error,setError] = useState(null);
    if (error && error !== '404') {
        throw error;
    }

    let count = 0
    if (productsArray){
        productsArray.forEach(e => {
            count = count + e.qty
        });
    }
    const [productsCount, setProductsCount] = useState(count)

    const [email,setEmail] = useState('')
    const [prepaidNumber,setPrepaidNumber] = useState('')
    const [emailError,setEmailError] = useState(false)
    const [payment,setPayment] = useState('')

    let cont;
    if (typeof(productsArray) !== 'undefined' && productsArray.length !== 0 && productsCount !== 0) {
        let paymentbuttons = <FastCheckoutButtons setError={setError} />          
        let otherpayments = <OtherPayments email={email} setEmail={setEmail} prepaidNumber={prepaidNumber} setPrepaidNumber={setPrepaidNumber} setEmailError={setEmailError} payment={payment} setPayment={setPayment} setError={setError} />            
        let productsview = <CheckoutProducts productsArray={productsArray} setProductsArray={setProductsArray} productsCount={productsCount} setProductsCount={setProductsCount} /> 
        let overview = <CheckoutOverview productsArray={productsArray} email={email} prepaidNumber={prepaidNumber} emailError={emailError} setEmailError={setEmailError} payment={payment} setError={setError} />      
        cont = <>{paymentbuttons}{otherpayments}{productsview}{overview}</>
    } 
    else {
        cont = 
        <div className='container t-center'>
            <h1>Kein Ticket?</h1>
            <p>Ohne ein Ticket geht es an dieser Stelle leider nicht weiter.</p>
            <Link to='/'><button>Zur Startseite</button></Link>
        </div>
    }
    
    return cont
     

}

export default CheckoutPage;
