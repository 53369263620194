import { Link } from 'react-router-dom';

const OrderSuccessPage = () => {  
    return (        
        <div className="container t-center">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 t-center">
                    <h1>Bestellung abgeschlossen</h1>
                    <p>Die Bestellung war erfolgreich!</p>
                    <p>Alle weiteren Informationen erhältst du in Kürze per Mail.</p>
                    <p><Link className='button' to="/">Zu den Tickets</Link></p>
                </div>
            </div>
        </div>
    );
}

export default OrderSuccessPage;