import { Component } from 'react';
import { Link } from 'react-router-dom';


class PageNotFound extends Component {

    render() {
        return (
            <div className='container t-center'>
                <h1>404 - Seite nicht gefunden</h1>
                <p>Die aufgerufene Seite existiert leider nicht bzw. nicht mehr.<br /><br /></p>     
                <p><Link className='button' to="/">Zu den Tickets</Link></p>
            </div>
        );
    }
}

export default PageNotFound;
