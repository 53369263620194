import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as GlobalStore from '../store/global/actions';
import ApiService from '../services/api.service';
import HelperService from '../services/helper.service';
import PageNotFound from './PageNotFound';

const ProductPage = () => {

    const dispatch = useDispatch();
    const qtys = useSelector(state => state.global.qtys);

    const queryParameters = new URLSearchParams(window.location.search);
    const path = queryParameters.get('path');
    let finalpath = path.split('/');
    const category = finalpath[0];
    const location = finalpath[1];
    const product = finalpath[2];

    const locationname = (location.charAt(0).toUpperCase() + location.slice(1)).replace(/ae/g, 'ä').replace(/oe/g, 'ö').replace(/ue/g, 'ü');
    const [error, setError] = useState(null);

    const appid = queryParameters.get('karlsplus-app-id');
    const appticketnumber = queryParameters.get('ticket-number');

    if (appid) {
        dispatch(GlobalStore.setAppId(appid));
    }

    if (appticketnumber) {
        dispatch(GlobalStore.setAppTicketNumber(appticketnumber));
    }
    else {
        dispatch(GlobalStore.setAppTicketNumber(null));
    }

    const [totalQty, setTotalQty] = useState(0);
    const [response, setResponse] = useState();
    const [activeLocation, setActiveLocation] = useState(path);


    if (error && error !== '404') {
        throw error;
    }

    const changeQtyDown = (e, id) => {
        let array = qtys
        let aid = array.findIndex(obj => obj.id === id)
        if (array[aid].qty !== 0) {
            array[aid].qty = array[aid].qty - 1
            dispatch(GlobalStore.setProductQtys(array));
            e.target.nextElementSibling.value = array[aid].qty;
            totalQty !== 0 ? setTotalQty(totalQty - 1) : setTotalQty(totalQty)
        }
    }

    const changeQtyUp = (e, id) => {
        let array = qtys
        let aid = array.findIndex(obj => obj.id === id)
        if (array[aid].maxqty === null || array[aid].qty < array[aid].maxqty) {
            array[aid].qty = array[aid].qty + 1
            dispatch(GlobalStore.setProductQtys(array));
            e.target.previousElementSibling.value = array[aid].qty;
            setTotalQty(totalQty + 1)
        }
    }

    const toggleDetails = (e) => {
        let opened = document.getElementsByClassName('productbox open');
        if (opened.length) {
            for (let item of opened) {
                if (item !== e.target.parentElement) {
                    item.classList.remove('open')
                }
            }
        }
        let parentclass = e.target.parentElement.classList;
        parentclass.contains('open') ? parentclass.remove('open') : parentclass.add('open')
    }

    const getQtyError = (qtys, products) => {
        let qtymatcherror = false
        products.forEach(p => {
            let match = qtys.find(obj => obj.id === p.id)
            if (typeof (match) === 'undefined') {
                qtymatcherror = true;
            }
        })
        return qtymatcherror
    }

    useEffect(() => {
        if (!response || activeLocation !== path) {
            setActiveLocation(path);
            if (global.config.shopurl) {
                const API_URL = global.config.express.url + '/product';
                const API_DATA = {
                    'shop-url': global.config.shopurl,
                    'category': category,
                    'location': location,
                    'product': product,
                    'app': global.config.inapp
                }
                ApiService.getData(API_URL, API_DATA).then((response) => {
                    if (typeof (response.apierror) !== 'undefined') {
                        setError(response.apierror);
                    }
                    else {
                        if (response['payment-providers']) {
                            dispatch(GlobalStore.setPayments(response['payment-providers']));
                        }

                        if (response.products) {
                            if (typeof (qtys) === 'undefined' || qtys.length === 0 || getQtyError(qtys, response.products) === true) {
                                let qtyarray = []
                                response.products.forEach(p => {

                                    let contingent;
                                    let maxqty = p['max-qty'] ? p['max-qty'] : 50;

                                    if (typeof (p.contingent) !== 'undefined') {
                                        contingent = p.contingent > 50 || p.contingent === null ? 50 : p.contingent;
                                        maxqty = p['max-qty'] && contingent > p['max-qty'] ? p['max-qty'] : contingent;
                                    }

                                    qtyarray.push({
                                        'id': p.id,
                                        'name': p.name,
                                        'price': p['product-price']['gross-amount'].amount,
                                        'tax': p['product-price']['tax-value'],
                                        'vat': p['product-price']['vat-amount'].amount,
                                        'maxqty': maxqty,
                                        'qty': 0
                                    })
                                })
                                dispatch(GlobalStore.setProductQtys(qtyarray));
                            }
                            else {
                                let totalQtyCount = 0
                                qtys.forEach(q => {
                                    totalQtyCount = totalQtyCount + q.qty
                                })
                                setTotalQty(totalQtyCount)
                            }
                        }
                        setResponse(response);
                    }
                }).catch((error) => {
                    setError('NO_CONNECTION')
                });
            }
            else {
                setError('PARAMS_MISSING');
            }
        }
    }, [response, category, location, product, qtys, dispatch, activeLocation, path]);

    let view;
    let products = [];
    let navbuttons = [];
    let buttonvisible = totalQty !== 0 ? true : false;

    if (response) {

        if (response.categories) {
            response.categories.forEach(e => {
                let imgpath = global.config.apiurl + '/' + e.image['relative-path'];
                navbuttons.push(
                    <Link className='navbox' key={e.id} to={'?path=' + e.identifier}><img src={imgpath} alt={e.name} /></Link>
                )
            });
        }

        if (response.products) {
            response.products.forEach(p => {
                let elementqty = qtys.find(obj => obj.id === p.id).qty
                products.push(
                    <div className='productbox' key={p.id}>
                        <span className='toggle' onClick={(e) => { toggleDetails(e) }}></span>
                        <span className='name'>{p.name}</span>
                        <span className='nameadd'>{p['description-short']}</span>
                        <span className='price'>{HelperService.formatPrice(p['product-price']['gross-amount'].amount)}</span>
                        {typeof (p.contingent) === 'undefined' || p.contingent !== 0
                            ?
                            <div className='inputholder'>
                                <span className='down' onClick={(e) => { changeQtyDown(e, p.id) }}>-</span>
                                <input className='quantity' type='number' value={elementqty} readOnly />
                                <span className='up' onClick={(e) => { changeQtyUp(e, p.id) }}>+</span>
                            </div>
                            :
                            <span className='nocontingent'>Ausverkauft</span>
                        }
                        <div className='togglecont'>
                            {p.image ? <img src={global.config.apiurl + '/' + p.image['relative-path']} alt={p.name} /> : ''}
                            <p>{p['description-long']}</p>
                        </div>
                    </div>
                )
            });
        }

        view =
            <div className='container t-center'>
                <div className='t-uppercase text top'>Deine Tickets für {locationname}</div>
                {navbuttons}
                {products}
                {buttonvisible ? <Link to='?path=checkout'><button className='next'>Weiter</button></Link> : ''}
            </div>
    }

    let content =
        error === '404'
            ? <PageNotFound />
            : view


    return content;

}

export default ProductPage;
