import { useState, useEffect } from 'react';
import ApiService from "../services/api.service";
import HelperService from '../services/helper.service';

const OverviewPage = () => {

    const [apiResponse, setApiResponse] = useState();

    const queryParameters = new URLSearchParams(window.location.search)
    const identifier = queryParameters.get("transitIdentifier")

    const [error, setError] = useState(null);
    if (error && error !== '404') {
        throw error;
    }

    const finishCheckout = (e) => {
        e.currentTarget.setAttribute('disabled', true)
        e.currentTarget.classList.add('loading')
        const API_URL = global.config.express.url + '/order/execute'
        const API_DATA = {
            'transit-identifier': identifier,
            'shop-url': global.config.shopurl
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                window.location.replace(response.url);
            }
        }).catch((error) => {
            setError('NO_CONNECTION')
        });
    }

    useEffect(() => {
        if (identifier) {
            const API_URL = global.config.express.url + '/order/details';
            const API_DATA = {
                'transit-identifier': identifier,
                'shop-url': global.config.shopurl
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    setApiResponse(response);
                }
            }).catch((error) => { setError(error) });
        }
        else {
            setError('NO_IDENTIFIER')
        }
    }, [identifier]);

    if (identifier && apiResponse) {

        let cont;

        if (apiResponse.products.length !== 0) {
            let shippingbox;
            let paymentbox;
            let amazonscript;
            if (apiResponse.payment['payment-provider'] === 'amazon') {
                shippingbox = <div id="addressBookWidgetDiv"></div>;
                paymentbox = <div className="pbox" id="walletWidgetDiv"></div>;
                amazonscript = document.createElement('script');
                amazonscript.innerHTML = apiResponse['script-content'];
                setTimeout(function () {
                    document.getElementById('walletWidgetDiv').appendChild(amazonscript);
                }, 1000);
            }


            const taxlines = Object.values(apiResponse.total['tax-amounts']).map(val =>
                <div key={val['vat-value']}>
                    <span className='left'>zzgl. {val['vat-value']}% MwSt.</span><span className='right t-right'>{HelperService.formatPrice(val['vat-amount'].amount)}</span>
                </div>
            )
            let products = []
            apiResponse.products.forEach(p => {
                products.push(
                    <div className='product' key={p.id}>
                        <span className='left name overview'>{p.quantity}x {p.name}</span>
                        <span className='right t-right sum overview'>{HelperService.formatPrice(p['gross-amount'].amount)}</span>
                        <span className='left small'>inkl. MwSt.</span>
                        <span className='right t-right small'>{HelperService.formatPrice(p['vat-amount'].amount)}</span>
                    </div>
                )
            });
            cont =
                <>
                    <div className="container t-center">
                        <h1>Bestellübersicht</h1>
                        {shippingbox}
                        {paymentbox}
                    </div>
                    <div className='container'>
                        <div className='products'>{products}</div>
                    </div>
                    <div className='container'>
                        <div className='overviewbox'>
                            <h2>Zusammenfassung</h2>
                            <span className='left'>Zwischensumme</span><span className='right t-right'>{HelperService.formatPrice(apiResponse.total['gross-amount'].amount)}</span>
                            <span className='left'>Versandkosten</span><span className='right t-right'>{HelperService.formatPrice(0)}</span>
                            <span className='left sum'>Gesamtsumme</span><span className='right t-right sum'>{HelperService.formatPrice(apiResponse.total['gross-amount'].amount)}</span>
                            <span className='left'>Gesamtnettosumme</span><span className='right t-right'>{HelperService.formatPrice(apiResponse.total['net-amount'].amount)}</span>
                            {taxlines}

                            <div className='dataprotection small'>
                                <strong>Datenschutz</strong><br />Ich habe die <a href="https://karls.de/datenschutz/" target="_blank" rel="noreferrer">Datenschutzbestimmungen</a> zur Kenntnis genommen und die <a href="https://www.karls-shop.de/Sicherheit/AGB/" target="_blank" rel="noreferrer">AGB</a> gelesen und bin mit ihnen einverstanden.
                            </div>
                            <div className='submitbox'>
                                <button className='submit full' onClick={(e) => finishCheckout(e)} disabled={apiResponse.payable ? false : true}>Zahlungspflichtig bestellen</button>
                            </div>
                        </div>
                    </div>
                </>
        }
        return cont;
    }

}

export default OverviewPage;
