import { useSelector } from 'react-redux';
import HelperService from '../../services/helper.service';

const FastCheckoutButtons = ({setError}) => {
    
    const products = useSelector(state => state.global.qtys)
    const payments = useSelector(state => state.global.payments)
    const appid = useSelector(state => state.global.appid)
    const appticketnumber = useSelector(state => state.global.appticketnumber)

    const setOrder = (e, payment) => {
        document.getElementsByClassName('submit')[0].setAttribute('disabled', true);
        let buttons = document.getElementsByClassName('payment')
        for ( var i = 0, l = buttons.length; i < l; i++ ){
            buttons[i].setAttribute('disabled', true);
        }
        e.currentTarget.classList.add('loading')
        let email = null
        HelperService.checkout(email,'',products,payments,payment,setError,appid,appticketnumber)        
    }

    let paymentButtons = [];
    let fastcheckoutproviders = [];

    payments.forEach((payment) => {    
        payment['payment-call-tupel'].forEach((item, i) => {
            if (item['payment-kind'] === 'Order' && payment.active){
                fastcheckoutproviders.push(item);
            }
        });
    });

    if (fastcheckoutproviders.length){
        paymentButtons.push(
            <div key="lt" className='linedtext'><span>Direkt bezahlen mit</span></div> 
        )

        fastcheckoutproviders.forEach((provider, key) => {
            let type = provider['payment-provider'];
            let paymenticon;
            if (provider['payment-provider-icon'] && provider['payment-provider-icon']['relative-path']){
                paymenticon = <img src={global.config.apiurl + '/' + provider['payment-provider-icon']['relative-path']} alt={provider['payment-provider-icon']['alt']} />
            }
            else {
                paymenticon = provider['payment-name'];
            }
            paymentButtons.push(
                <button
                    onClick={(e) => setOrder(e, provider['id'])}
                    className={'payment fastcheckout ' + type}
                    key={key}
                >
                   {paymenticon}
                </button>
            );
        });

    }
    
    
    return (
        <div className='container'>
            {paymentButtons} 
        </div>
    );                   
}

export default FastCheckoutButtons;
