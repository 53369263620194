import { useSelector, useDispatch } from 'react-redux';
import * as GlobalStore from '../../store/global/actions';
import HelperService from '../../services/helper.service';

const CheckoutProducts = ({productsArray, setProductsArray, productsCount, setProductsCount}) => {
    
    const dispatch = useDispatch()
    const qtys = useSelector(state => state.global.qtys)

    const changeQtyDown = (e, id) => {        
        let array = qtys
        let aid = array.findIndex(obj => obj.id === id)
        if (array[aid].qty > 1){
            array[aid].qty = array[aid].qty - 1
            dispatch(GlobalStore.setProductQtys(array))
            setProductsArray([...array])
            setProductsCount(productsCount - 1)
            e.target.nextElementSibling.value = array[aid].qty
        }
        else {
            deleteProduct(id)
        }
    }

    const changeQtyUp = (e, id) => {
        let array = qtys
        let aid = array.findIndex(obj => obj.id === id)
        if (array[aid].maxqty === null || array[aid].qty < array[aid].maxqty) {
            array[aid].qty = array[aid].qty + 1
            dispatch(GlobalStore.setProductQtys(array))
            setProductsArray([...array])
            setProductsCount(productsCount + 1)
            e.target.previousElementSibling.value = array[aid].qty
        }
    }

    const deleteProduct = (id) => {
        let array = qtys
        let aid = array.findIndex(obj => obj.id === id)
        setProductsCount(productsCount - array[aid].qty)
        array[aid].qty = 0
        dispatch(GlobalStore.setProductQtys(array))
        setProductsArray([...array])
    }
    
    let products = []

    productsArray.forEach(p => {
        let id = productsArray.findIndex(obj => obj.id === p.id)
        if (productsArray[id].qty !== 0){            
            products.push(
                <div className='product' key={p.id}>                        
                    <span className='left name'>{p.name}</span>
                    <span className='right t-right close' onClick={() => deleteProduct(p.id)}>X</span>                       
                    <div className='count'>
                        <span className='left'>Anzahl</span>
                        <div className='inputholder right'>
                            <span className='down' onClick={(e) => { changeQtyDown(e, p.id) }}>-</span>
                            <input className='quantity' type='number' value={productsArray[id].qty} readOnly />
                            <span className='up' onClick={(e) => { changeQtyUp(e, p.id) }}>+</span>
                        </div>
                    </div>                        
                    <span className='left'>inkl. MwSt.</span>
                    <span className='right t-right'>{HelperService.formatPrice(productsArray[id].vat * productsArray[id].qty)}</span>
                    <span className='left'>Summe</span>
                    <span className='right t-right sum'>{HelperService.formatPrice(productsArray[id].price * productsArray[id].qty)}</span>                        
                </div>
            )
        }

    });

    let productsview = 
        <div className='container'>
            <div className='products'>{products}</div>                
        </div>

    return productsview;   
    


}

export default CheckoutProducts;
