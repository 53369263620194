import { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const OtherPayments = ({ email, setEmail, prepaidNumber, setPrepaidNumber, setEmailError, payment, setPayment }) => {

    const payments = useSelector(state => state.global.payments)
    const infoboxref = useRef(null);

    const [showPrepaidField, setShowPrepaidField] = useState(false);

    useEffect(() => {

        let otcount = 0;
        payments.forEach((payment) => {
            payment['payment-call-tupel'].forEach((item, i) => {
                if (item['payment-kind'] === 'OneTime' && payment.active) {
                    if (otcount === 0 && item['payment-method-value'] === 'prepaid') {
                        setShowPrepaidField(true)
                    }
                    otcount++;
                }
            });
        });
    }, [payments])

    const handleClickOutside = () => {
        if (infoboxref.current && email === '') {
            infoboxref.current.classList.remove('hidden');
        }
    }
    document.addEventListener('mousedown', handleClickOutside());

    const changePayment = (id, method) => {
        setPrepaidNumber();
        setShowPrepaidField(method === 'prepaid' ? true : false);
        setPayment(id);
    }


    const clickMail = () => {
        infoboxref.current.classList.add('hidden');
    }

    const changeMail = (value) => {

        value = value.trim();

        if (value === '') {
            infoboxref.current.classList.remove('hidden');
        }
        setEmail(value)
        setEmailError(false)
    }

    let paymentButtons = [];
    let onetimecheckoutproviders = [];
    let fastCheckoutAvailable = false;

    payments.forEach((payment) => {
        payment['payment-call-tupel'].forEach((item, i) => {
            if (item['payment-kind'] === 'OneTime' && payment.active) {
                onetimecheckoutproviders.push(item);
            }
            if (item['payment-kind'] === 'Order' && payment.active) {
                fastCheckoutAvailable = true
            }
        });
    });

    if (onetimecheckoutproviders.length) {
        onetimecheckoutproviders.forEach((provider, key) => {
            let paymenticon;
            if (provider['payment-provider-icon'] && provider['payment-provider-icon']['relative-path']) {
                paymenticon = <img src={global.config.apiurl + '/' + provider['payment-provider-icon']['relative-path']} alt={provider['payment-provider-icon']['alt']} />
            }
            if (provider['payment-method-value'] === 'apple_pay' && (typeof (window.ApplePaySession) === 'undefined' || !window.ApplePaySession.canMakePaymentsWithActiveCard())) {
                return
            }
            else {

                let prepaidfield = provider['payment-method-value'] === 'prepaid' ? <><label>Prepaid-Nummer*</label><div className='forminputholder'><input className='prepaid' type='text' value={prepaidNumber} onChange={(e) => setPrepaidNumber(e.target.value)} /></div></> : '';

                paymentButtons.push(
                    <Fragment key={key}>
                        <button
                            onClick={(e) => changePayment(provider['id'], provider['payment-method-value'])}
                            className={'paymentoption' + (provider['id'] === payment || (payment === '' && key === 0) ? ' activ' : '')}
                            dataid={provider['id']}

                        >
                            {provider['payment-name']} {paymenticon}
                        </button>
                        {showPrepaidField ? prepaidfield : ''}
                    </Fragment>
                );
            }
        });
    }

    let linedtext = fastCheckoutAvailable ? <div className='linedtext'><span>Oder andere Zahlungsmittel</span></div> : ''

    return (
        <div className='container'>
            {linedtext}
            <div className='underlinedtext'>Persönliche Informationen</div>
            <label>E-Mail-Adresse*</label>
            <div className='forminputholder'>
                <input className='email' type='text' value={email} onClick={() => clickMail()} onChange={(e) => changeMail(e.target.value)} />
                <div className='inputinfobox t-center' ref={infoboxref}>
                    <strong>Fast geschafft!</strong>Karlchen setzt sich sofort daran deine Tickets fertig zu machen. Bitte teile uns deine E-Mail-Adresse für den Versand deiner Tickets mit.
                </div>
            </div>
            <div className='dataprotection'>
                <p>
                    <strong>Datenschutz</strong><br />Ich habe die <a href="https://karls.de/datenschutz/" target="_blank" rel="noreferrer">Datenschutzbestimmungen</a> zur Kenntnis genommen und die <a href="https://www.karls-shop.de/Sicherheit/AGB/" target="_blank" rel="noreferrer">AGB</a> gelesen und bin mit ihnen einverstanden.
                </p>
            </div>
            <div className='paymentselection'>
                {paymentButtons}
            </div>
        </div>
    );
}

export default OtherPayments;
