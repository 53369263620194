const initialState = {
    prepagehidden: false,
    appid: null,
    appticketnumber: null,
    qtys: [],
    payments: []
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_APIERROR': {
            return {
                ...state,
                apierror: action.apierror,
            };
        }
        case 'SET_PREPAGEHIDDEN': {
            return {
                ...state,
                prepagehidden: action.prepagehidden,
            };
        }
        case 'SET_APPID': {
            return {
                ...state,
                appid: action.appid,
            };
        }
        case 'SET_APPTICKETNUMBER': {
            return {
                ...state,
                appticketnumber: action.appticketnumber,
            };
        }
        case 'SET_PRODUCTQTYS': {
            return {
                ...state,
                qtys: action.qtys,
            };
        }
        case 'RESET_PRODUCTQTYS': {
            return {
                ...state,
                qtys: [],
            };
        }
        case 'SET_PAYMENTS': {
            return {
                ...state,
                payments: action.payments,
            };
        }
        case 'RESET_PAYMENTS': {
            return {
                ...state,
                payments: [],
            };
        }

        default: {
            return state;
        }
    }
};

export default Reducer;
